<template>
  <v-menu
    :close-on-content-click="false"
    v-model="menu"
    transition="scale-transition"
    offset-y
    :nudge-right="40"
    max-width="290px"
    min-width="290px"
  >
    <template v-slot:activator="{ on }">
      <span v-on="!writtable ? on : ''">
        <v-text-field
          slot="activator"
          :backgroundColor="backgroundColor"
          :filled="filled"
          :dense="dense"
          :hide-details="hideDetails"
          :single-line="singleLine"
          :class="[customClass]"
          :value="dateformFormatted"
          :readonly="!writtable"
          :disabled="disabled"
          :error-messages="errors"
          v-mask="writtable ? [mask] : ''"
          :placeholder="placeHolderFormats[mask]"
          v-on:input="debounceInput($event)"
        >
          <template #label>
            {{ label }}<RedAsterisk v-if="redAsterisk" />
          </template>
          <template slot="append" v-if="clearable">
            <v-icon @click="clear" v-if="!disabled && dateform">close</v-icon>
          </template>
          <template :slot="prependInner ? 'prepend-inner' : 'prepend'">
            <v-btn
              v-if="!useF2gIcon"
              elevation="2"
              icon
              :disabled="disabled"
              @click="menu = true"
            >
              <v-icon :color="customColor">event</v-icon>
            </v-btn>
            <F2gIcon
              v-else
              :stroke="customColor"
              :height="22"
              :width="22"
              :strokeWidth="strokeWidth"
              value="calendar"
              @click="menu = true"
              class="cursor-pointer"
            />
          </template>
        </v-text-field>
      </span>
    </template>
    <v-date-picker
      :value="dateform ? dateform : null"
      no-title
      scrollable
      :min="minDate"
      :max="maxDate"
      locale="i18n.locale"
      :type="checkType()"
      :disabled="disabled"
      :first-day-of-week="
        $PageUtils.getLanguageSplittedMinus($i18n.locale) === 'en' ? 0 : 1
      "
      @input="emitInput"
    ></v-date-picker>
  </v-menu>
</template>

<script>
import moment from "moment-timezone";
import DateUtils from "@/flit2go-web-core/util/dateUtil";

export default {
  name: "F2gDatePicker",
  components: {
    RedAsterisk: () =>
      import("@/flit2go-web-core/components/fields/f2g-red-asterisk"),
  },
  props: {
    label: String,
    errors: Array,
    minDate: String,
    maxDate: String,
    value: [String, Number],
    disabled: { type: Boolean, required: false, default: false },
    type: String,
    maskFormat: { type: String, required: false, default: "##-##-####" },
    clearable: { type: Boolean, default: true, required: false },
    writtable: { type: Boolean, default: true, required: false },
    filled: { type: Boolean, default: false, required: false },
    dense: { type: Boolean, default: false, required: false },
    singleLine: { type: Boolean, default: false, required: false },
    hideDetails: { type: Boolean, default: false, required: false },
    customClass: { type: String, default: "", required: false },
    customColor: { type: String, default: "", required: false },
    backgroundColor: { type: String, default: "", required: false },
    name: { type: String, default: "", required: false },
    useF2gIcon: { type: Boolean, default: false, required: false },
    prependInner: { type: Boolean, default: false, required: false },
    strokeWidth: { type: Number, default: 4, required: false },
    redAsterisk: { type: Boolean, default: false, required: false },
  },
  data() {
    return {
      DateUtils,
      dateform: "",
      menu: null,
      placeHolderFormats: {
        "##-##-####": "DD-MM-YYYY",
        "####-##-##": "YYYY-MM-DD",
      },
    };
  },
  watch: {
    dateform() {
      if (this.dateform && this.dateform !== "Invalid date") {
        this.$emit("input", this.dateform, this.name);
      }
    },
    value() {
      this.dateform = this.value;
    },
    disabled() {
      if (this.disabled) {
        this.dateform = this.value;
      }
    },
  },
  computed: {
    mask() {
      let mask = "";
      if (this.maskFormat) {
        mask = this.maskFormat;
      } else if (
        this.$PageUtils.getLanguageSplittedMinus() === "es" ||
        this.$PageUtils.getLanguageSplittedMinus() === "ca" ||
        this.$PageUtils.getLanguageSplittedMinus() === "it" ||
        this.$PageUtils.getLanguageSplittedMinus() === "fr" ||
        this.$PageUtils.getLanguageSplittedMinus() === "pt" ||
        this.$PageUtils.getLanguageSplittedMinus() === "de"
      ) {
        mask = "##-##-####";
      } else {
        mask = "####-##-##";
      }
      return mask;
    },
    dateformFormatted() {
      return this.dateform
        ? moment(
            typeof this.dateform === "string" && !this.dateform.includes("-")
              ? parseInt(this.dateform)
              : this.dateform
          ).format(this.placeHolderFormats[this.maskFormat])
        : "";
    },
  },
  mounted() {
    this.dateform = this.value;
  },
  methods: {
    // eslint-disable-next-line
    debounceInput: _.debounce(function (date) {
      const dateFormatted = moment(
        date,
        this.placeHolderFormats[this.maskFormat]
      ).format("YYYY-MM-DD");
      if (dateFormatted && dateFormatted !== "Invalid date") {
        this.dateform = dateFormatted;
      } else {
        this.dateform = "";
      }
    }, 1000),
    emitInput(value) {
      this.menu = false;
      this.dateform = value;
      this.$emit("input", value);
    },
    checkType() {
      return this.type ? this.type : "date";
    },
    clear() {
      this.dateform = "";
      this.$emit("input", "");
    },
  },
};
</script>

<style lang="scss">
.cursor-pointer {
  cursor: pointer;
}
</style>
